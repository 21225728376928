import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CButton
} from '@coreui/react';
import {
    Form,
    useActionData,
    useLoaderData,
    useNavigation
} from 'react-router-dom';
import { memo } from 'react';
import Alert from '../../components/Alert';
import Input from '../../components/Input';
import {
    handleActions,
    loadResource
} from '../../utils/requests/general-request';
import { ENV } from '../../utils/constants';
import { cilPenNib } from '@coreui/icons';

const Settings = () => {
    const loadedData = useLoaderData();
    const actionData = useActionData();
    const navigation = useNavigation();

    return (
        <CRow className='justify-content-center'>
            <CCol sm={4}>
                <Alert data={actionData} message='Operation successful.' />
                <CCard className='mt-4'>
                    <CCardHeader className='d-flex justify-content-space-between'>
                        <small>Settings</small>
                    </CCardHeader>
                    <CCardBody>
                        <Form method='post' noValidate>
                            <CRow>
                                <CCol xs={12}>
                                    <Input
                                        element='select'
                                        id='mode'
                                        name='mode'
                                        labelTitle='Maintenance Mode'
                                        icon={cilPenNib}
                                        data={actionData}
                                        defaultValue={
                                            loadedData && loadedData.data
                                                ? loadedData.data.mode
                                                : ''
                                        }
                                    >
                                        <option value=''>Select mode</option>
                                        <option value='up'>Up</option>
                                        <option value='down'>Down</option>
                                    </Input>
                                </CCol>
                            </CRow>
                            <CRow className='my-2 d-flex'>
                                <CCol>
                                    <CButton
                                        className='btn-facebook'
                                        type='submit'
                                        name='intent'
                                        value='create'
                                        disabled={
                                            navigation.state === 'submitting' ||
                                            navigation.state === 'loading'
                                        }
                                    >
                                        <span>
                                            {navigation.state === 'submitting'
                                                ? 'Submitting...'
                                                : 'Submit'}
                                        </span>
                                    </CButton>
                                </CCol>
                            </CRow>
                        </Form>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    );
};

export const action = async ({ request }) => {
    return await handleActions(request, {
        uri: `${ENV.adminBaseUrl}/operations/maintenance`,
        isFormData: false,
        method: 'POST'
    });
};

export const loader = async ({ request }) => {
    return await loadResource(request, {
        uri: `${ENV.adminBaseUrl}/operations/maintenance/status`
    });
};

export default memo(Settings);
