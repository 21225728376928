import {
    CTable,
    CTableDataCell,
    CTableRow,
    CTableHeaderCell,
    CTableHead,
    CTableBody
} from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import { memo, useContext } from 'react';
import { AuthContext } from '../store/auth';
import { ROLES } from '../utils/constants';

const PositionTable = ({ positions }) => {
    const navigate = useNavigate();

    const { user } = useContext(AuthContext);
    const clickHandler = id => {
        if ([ROLES.admin, ROLES.super].includes(user.roles)) {
            navigate(`/dashboard/positions/${id}`);
        }
    };

    return (
        <CTable hover striped responsive='sm'>
            <CTableHead>
                <CTableRow>
                    <CTableHeaderCell>#</CTableHeaderCell>
                    <CTableHeaderCell>Title</CTableHeaderCell>
                </CTableRow>
            </CTableHead>
            <CTableBody>
                {positions &&
                    positions.map(position => (
                        <CTableRow
                            role='button'
                            key={position.id}
                            onClick={() => clickHandler(position.id)}
                        >
                            <CTableDataCell>{position.count}</CTableDataCell>
                            <CTableDataCell>{position.title}</CTableDataCell>
                        </CTableRow>
                    ))}
            </CTableBody>
        </CTable>
    );
};

export default memo(PositionTable);
