import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CButton
} from '@coreui/react';
import {
    Form,
    useNavigation,
    useLoaderData,
    useActionData,
    useParams,
    useNavigate
} from 'react-router-dom';
import { cilUser, cilBadge, cilCalendar, cilAsterisk } from '@coreui/icons';
import { memo, useContext, useState } from 'react';
import Input from '../../components/Input';
import Alert from '../../components/Alert';
import { useRedirect } from '../../hooks/redirect';
import {
    deleteHandler,
    handleActions,
    loadResource
} from '../../utils/requests/general-request';
import { AuthContext } from '../../store/auth';
import { ENV, POST_STATUS, ROLES } from '../../utils/constants';
import WarningModal from '../../components/modals/WarningModal';
import { CustomDate } from '@church/utilities';

const announcementStatus = [
    { name: 'Select Status', value: '' },
    { name: 'Published', value: POST_STATUS.published },
    { name: 'Archived', value: POST_STATUS.archived },
    { name: 'Draft', value: POST_STATUS.draft }
];

const Announcement = () => {
    const [disabled, setDisabled] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const loadedData = useLoaderData();
    const actionData = useActionData();
    const navigation = useNavigation();

    const navigate = useNavigate();

    const { id } = useParams();

    const authCtx = useContext(AuthContext);
    // Redirect to users!
    useRedirect(actionData, '/dashboard/announcements', true);

    const disableInputField = () => setDisabled(disabled => !disabled);

    const deleteAnnouncement = async () => {
        const uri = `${ENV.adminBaseUrl}/announcements/${id}`;
        const isDeleted = await deleteHandler(uri);
        if (isDeleted) {
            setShowModal(false);
            navigate('/dashboard/announcements');
        }
    };

    return (
        <>
            {showModal && (
                <WarningModal
                    title='Delete'
                    visible={showModal}
                    onClose={() => setShowModal(false)}
                    type='button'
                    onClick={deleteAnnouncement}
                />
            )}

            <CRow className='justify-content-center'>
                {[ROLES.admin, ROLES.super].includes(authCtx.user.roles) && (
                    <CCol md={8}>
                        <Alert
                            data={actionData}
                            message='Announcement updated successfully.'
                        />
                        <CCard>
                            <CCardHeader className='d-flex justify-content-between'>
                                <small> Announcement Details</small>

                                <CButton
                                    className='btn btn-success text-white'
                                    type='button'
                                    onClick={disableInputField}
                                >
                                    Edit
                                </CButton>
                            </CCardHeader>
                            <CCardBody>
                                <Form method='post' noValidate>
                                    <CRow>
                                        <CCol>
                                            <Input
                                                element='input'
                                                type='text'
                                                id='title'
                                                name='title'
                                                placeholder='Title'
                                                labelTitle='Title'
                                                icon={cilAsterisk}
                                                data={actionData}
                                                defaultValue={
                                                    loadedData.data.title
                                                }
                                                disabled={disabled}
                                            />
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol>
                                            <Input
                                                element='textarea'
                                                type='textarea'
                                                id='description'
                                                name='description'
                                                placeholder='Description'
                                                labelTitle='Description'
                                                icon={cilUser}
                                                data={actionData}
                                                defaultValue={
                                                    loadedData.data.description
                                                }
                                                disabled={disabled}
                                            />
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={6}
                                            xl={6}
                                        >
                                            <Input
                                                element='input'
                                                type='datetime-local'
                                                id='start_date'
                                                name='start_date'
                                                placeholder='Start Date'
                                                labelTitle='Start Date'
                                                icon={cilCalendar}
                                                data={actionData}
                                                defaultValue={CustomDate.getDateTimeLocal(
                                                    loadedData.data.startDate
                                                )}
                                                disabled={disabled}
                                            />
                                        </CCol>
                                        <CCol
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={6}
                                            xl={6}
                                        >
                                            <Input
                                                element='input'
                                                type='datetime-local'
                                                id='end_date'
                                                name='end_date'
                                                placeholder='End Date'
                                                labelTitle='End Date'
                                                icon={cilCalendar}
                                                data={actionData}
                                                defaultValue={CustomDate.getDateTimeLocal(
                                                    loadedData.data.endDate
                                                )}
                                                disabled={disabled}
                                            />
                                        </CCol>
                                    </CRow>

                                    <CRow>
                                        <CCol>
                                            <Input
                                                element='select'
                                                id='status'
                                                name='status'
                                                placeholder='Status'
                                                labelTitle='Status'
                                                icon={cilBadge}
                                                data={actionData}
                                                defaultValue={
                                                    loadedData.data.status
                                                }
                                                disabled={disabled}
                                            >
                                                {announcementStatus.map(
                                                    status => (
                                                        <option
                                                            value={status.value}
                                                            key={status.value}
                                                        >
                                                            {status.name}
                                                        </option>
                                                    )
                                                )}
                                            </Input>
                                        </CCol>
                                    </CRow>

                                    <div className='my-4 d-flex gap-4'>
                                        <CButton
                                            className='btn-facebook'
                                            type='submit'
                                            name='intent'
                                            value='edit'
                                            disabled={
                                                navigation.state ===
                                                    'submitting' ||
                                                navigation.state ===
                                                    'loading' ||
                                                disabled
                                            }
                                        >
                                            <span>
                                                {navigation.state ===
                                                'submitting'
                                                    ? 'Submitting...'
                                                    : 'Submit'}
                                            </span>
                                        </CButton>
                                        <CButton
                                            className='btn btn-danger'
                                            type='button'
                                            onClick={() => setShowModal(true)}
                                        >
                                            <span className='text-white'>
                                                Delete
                                            </span>
                                        </CButton>
                                    </div>
                                </Form>
                            </CCardBody>
                        </CCard>
                    </CCol>
                )}
            </CRow>
        </>
    );
};

export const action = async ({ request, params }) => {
    return await handleActions(request, {
        uri: `${ENV.adminBaseUrl}/announcements/${params.id}`,
        isFormData: false,
        method: 'PATCH'
    });
};

export const loader = async ({ request, params }) => {
    return await loadResource(request, {
        uri: `${ENV.adminBaseUrl}/announcements/${params.id}`
    });
};

export default memo(Announcement);
