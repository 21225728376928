import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CButton
} from '@coreui/react';
import {
    Form,
    useNavigation,
    useLoaderData,
    useActionData,
    useParams,
    useNavigate
} from 'react-router-dom';
import { cilBadge } from '@coreui/icons';
import { memo, useContext, useState } from 'react';
import Input from '../../components/Input';
import Alert from '../../components/Alert';
import { useRedirect } from '../../hooks/redirect';
import { AuthContext } from '../../store/auth';
import { ENV, ROLES } from '../../utils/constants';
import {
    deleteHandler,
    handleActions,
    loadResource
} from '../../utils/requests/general-request';
import WarningModal from '../../components/modals/WarningModal';

const Position = () => {
    const [disabled, setDisabled] = useState(true);
    const [showModal, setShowModal] = useState(false);

    const loadedData = useLoaderData();
    const actionData = useActionData();
    const navigation = useNavigation();

    const { id } = useParams();
    const navigate = useNavigate();

    const authCtx = useContext(AuthContext);
    // On success, redirect to events!
    useRedirect(actionData, '/dashboard/positions', true);

    const disableInputField = () => setDisabled(disabled => !disabled);

    const deletePositionHandler = async () => {
        const isDeleted = await deleteHandler(
            `${ENV.adminBaseUrl}/positions/${id}`
        );
        if (isDeleted) {
            setShowModal(false);
            navigate('/dashboard/positions');
        }
    };

    return (
        <>
            {showModal && (
                <WarningModal
                    title='Delete'
                    visible={showModal}
                    onClose={() => setShowModal(false)}
                    type='button'
                    onClick={deletePositionHandler}
                />
            )}
            <CRow className='justify-content-center'>
                {[ROLES.admin, ROLES.super].includes(authCtx.user.roles) && (
                    <>
                        <CCol md={4}>
                            <Alert
                                data={actionData}
                                message='Position updated successfully.'
                            />
                            <CCard className='mb-4'>
                                <CCardHeader className='d-flex justify-content-between'>
                                    <small> Position Details</small>

                                    <CButton
                                        className='btn btn-success text-white'
                                        type='button'
                                        onClick={disableInputField}
                                    >
                                        Edit
                                    </CButton>
                                </CCardHeader>
                                <CCardBody>
                                    <Form noValidate method='post'>
                                        <CRow>
                                            <CCol xs={12}>
                                                <Input
                                                    element='input'
                                                    type='text'
                                                    id='title'
                                                    name='title'
                                                    placeholder='Title'
                                                    labelTitle='Title'
                                                    icon={cilBadge}
                                                    data={actionData}
                                                    defaultValue={
                                                        loadedData.data.title
                                                    }
                                                    disabled={disabled}
                                                />
                                            </CCol>
                                        </CRow>
                                        {/* Hidden input to allow for update when file is involved */}
                                        <input
                                            id='_method'
                                            type='hidden'
                                            name='_method'
                                            value='PATCH'
                                        />
                                        <div className='my-2 d-flex align-items-center gap-4'>
                                            <CButton
                                                className='btn-facebook my-2'
                                                type='submit'
                                                name='intent'
                                                value='edit'
                                                disabled={
                                                    navigation.state ===
                                                        'submitting' ||
                                                    navigation.state ===
                                                        'loading' ||
                                                    disabled
                                                }
                                            >
                                                <span>
                                                    {navigation.state ===
                                                    'submitting'
                                                        ? 'Submitting...'
                                                        : 'Submit'}
                                                </span>
                                            </CButton>

                                            {navigation.state !==
                                                'submitting' && (
                                                <CButton
                                                    className='btn btn-danger'
                                                    type='button'
                                                    onClick={() =>
                                                        setShowModal(true)
                                                    }
                                                >
                                                    <span className='text-white'>
                                                        Delete
                                                    </span>
                                                </CButton>
                                            )}
                                        </div>
                                    </Form>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </>
                )}
            </CRow>
        </>
    );
};

export const action = async ({ request, params }) => {
    return await handleActions(request, {
        uri: `${ENV.adminBaseUrl}/positions/${params.id}`,
        isFormData: false,
        method: 'PATCH'
    });
};

export const loader = async ({ request, params }) => {
    return await loadResource(request, {
        uri: `${ENV.adminBaseUrl}/positions/${params.id}`
    });
};

export default memo(Position);
