import {
    Link,
    Form,
    useActionData,
    useNavigation,
    useParams
} from 'react-router-dom';
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CRow
} from '@coreui/react';

import { cilLockLocked } from '@coreui/icons';

import Input from '../../../components/Input';
import { useRedirect } from '../../../hooks/redirect';
import { disableButton } from '../../../utils/helpers';
import Alert from '../../../components/Alert';
import { passwordReset } from '../../../utils/requests/general-request';

const PasswordReset = () => {
    const data = useActionData();
    const navigation = useNavigation();

    const { token } = useParams();

    useRedirect(data, '/', true);

    return (
        <div className='bg-light min-vh-100 d-flex flex-row align-items-center'>
            <CContainer>
                <CRow className='justify-content-center'>
                    <CCol md={6}>
                        <Alert
                            data={data}
                            message='Password reset successfully.'
                        />
                        <CCardGroup>
                            <CCard className='p-4'>
                                <CCardBody>
                                    <Form method='post' noValidate>
                                        <h3 className='text-center text-uppercase'>
                                            Password Reset
                                        </h3>
                                        <p className='text-medium-emphasis text-center'>
                                            Please enter a new password.
                                        </p>
                                        <CRow>
                                            <CCol xs={12}>
                                                <Input
                                                    element='input'
                                                    type='password'
                                                    id='password'
                                                    name='password'
                                                    placeholder='New Password'
                                                    labelTitle='New Password'
                                                    data={data}
                                                    icon={cilLockLocked}
                                                />
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <CCol xs={12}>
                                                <Input
                                                    element='input'
                                                    type='password'
                                                    id='password_confirmation'
                                                    name='password_confirmation'
                                                    placeholder='Repeat New Password'
                                                    labelTitle='New Password Repeat'
                                                    data={data}
                                                    icon={cilLockLocked}
                                                />
                                            </CCol>
                                            <input
                                                element='input'
                                                type='hidden'
                                                id='token'
                                                name='token'
                                                value={token}
                                            />
                                        </CRow>
                                        <CRow className='my-2 d-flex align-items-center'>
                                            <CCol xs={6} md={6} lg={6} xl={6}>
                                                <CButton
                                                    color='primary'
                                                    type='submit'
                                                    disabled={disableButton(
                                                        navigation,
                                                        data
                                                    )}
                                                >
                                                    {navigation.state ===
                                                    'submitting'
                                                        ? 'Submitting...'
                                                        : 'Submit'}
                                                </CButton>
                                            </CCol>
                                            {navigation.state !==
                                                'submitting' && (
                                                <CCol
                                                    xs={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <div
                                                        style={{
                                                            width: 'max-content',
                                                            float: 'right'
                                                        }}
                                                    >
                                                        <Link to='/'>
                                                            Login?
                                                        </Link>
                                                    </div>
                                                </CCol>
                                            )}
                                            {navigation.state !==
                                                'submitting' && (
                                                <CCol
                                                    xs={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <div
                                                        style={{
                                                            marginTop: '1rem'
                                                        }}
                                                    >
                                                        <Link to='/password-reset-request'>
                                                            Need fresh token?
                                                        </Link>
                                                    </div>
                                                </CCol>
                                            )}
                                        </CRow>
                                    </Form>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    );
};

export const action = async ({ request, params }) => {
    return await passwordReset(request, params);
};

export default PasswordReset;
