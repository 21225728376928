import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CButton
} from '@coreui/react';
import { Form, useActionData, useNavigation } from 'react-router-dom';
import { cilStar, cilPencil, cilCalendar } from '@coreui/icons';
import { useRedirect } from '../../hooks/redirect';
import Alert from '../../components/Alert';
import Input from '../../components/Input';
import { disableButton } from '../../utils/helpers';
import { handleActions } from '../../utils/requests/general-request';
import { ENV } from '../../utils/constants';

const NewAnnouncement = () => {
    const data = useActionData();
    const navigation = useNavigation();
    // Redirect conditionally!
    useRedirect(data, '/dashboard/announcements', true);

    return (
        <CRow className='justify-content-center'>
            <CCol md={6}>
                <Alert
                    data={data}
                    message='Announcement created successfully.'
                />
                <CCard>
                    <CCardHeader>
                        <small> New Announcement Form</small>
                    </CCardHeader>
                    <CCardBody>
                        <Form method='post' noValidate>
                            <CRow>
                                <CCol xs={12}>
                                    <Input
                                        element='input'
                                        type='text'
                                        id='title'
                                        name='title'
                                        placeholder='Title'
                                        labelTitle='Title'
                                        data={data}
                                        icon={cilStar}
                                    />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol xs={12}>
                                    <Input
                                        element='textarea'
                                        type='textarea'
                                        id='description'
                                        name='description'
                                        placeholder='Description'
                                        labelTitle='Description'
                                        data={data}
                                        icon={cilPencil}
                                    />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol xs={12} sm={12} lg={6} xl={6}>
                                    <Input
                                        element='input'
                                        type='datetime-local'
                                        id='start_date'
                                        name='start_date'
                                        placeholder='Start Date'
                                        labelTitle='Start Date'
                                        icon={cilCalendar}
                                        data={data}
                                    />
                                </CCol>
                                <CCol xs={12} sm={12} lg={6} xl={6}>
                                    <Input
                                        element='input'
                                        type='datetime-local'
                                        id='end_date'
                                        name='end_date'
                                        placeholder='End Date'
                                        labelTitle='End Date'
                                        icon={cilCalendar}
                                        data={data}
                                    />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol xs={12} sm={12}>
                                    <CButton
                                        className='btn-facebook my-2'
                                        type='submit'
                                        name='intent'
                                        value='create'
                                        disabled={disableButton(navigation, data)}
                                    >
                                        <span>
                                            {navigation.state === 'submitting'
                                                ? 'Submitting...'
                                                : 'Submit'}
                                        </span>
                                    </CButton>
                                </CCol>
                            </CRow>
                        </Form>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    );
};

export const action = async ({ request }) => {
    return await handleActions(request, {
        uri: `${ENV.adminBaseUrl}/announcements`,
        isFormData: false,
        method: 'POST'
    });
};

export default NewAnnouncement;
