import { CAlert } from '@coreui/react';
import { memo, Suspense } from 'react';
import { Await, defer, useLoaderData } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import { getDataFromStorage } from '../../utils/helpers';
import { ENV } from '../../utils/constants';
import GalleryList from '../../components/GalleryList';
import NoData from '../../components/NoData';

const Galleries = () => {
    const { galleries } = useLoaderData();

    return (
        <Suspense fallback={<Spinner asOverlay />}>
            {galleries && galleries.length > 0 ? (
                <Await
                    resolve={galleries}
                    errorElement={
                        <CAlert color='danger'>An error occurred!</CAlert>
                    }
                    children={el => <GalleryList galleries={el} />}
                />
            ) : (
                <NoData title='Galleries' />
            )}
        </Suspense>
    );
};

export const loader = async () => {
    return defer({ galleries: await getGalleries() });
};

export const getGalleries = async () => {
    const { token } = getDataFromStorage();
    try {
        const res = await fetch(`${ENV.adminBaseUrl}/galleries`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if (!res.ok) throw res;
        const resData = await res.json();
        return resData.data;
    } catch (error) {
        return error;
    }
};

export default memo(Galleries);
