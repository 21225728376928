import { CAlert } from '@coreui/react';
import { memo, Suspense } from 'react';
import { Await, defer, useLoaderData } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import { getDataFromStorage, transformedData } from '../../utils/helpers';
import { ENV } from '../../utils/constants';
import PositionTable from '../../components/PositionTable';
import NoData from '../../components/NoData';

const Postions = () => {
    const { positions } = useLoaderData();

    return (
        <Suspense fallback={<Spinner asOverlay />}>
            {positions && positions.length > 0 ? (
                <Await
                    resolve={positions}
                    errorElement={
                        <CAlert color='danger'>An error occurred!</CAlert>
                    }
                    children={el => <PositionTable positions={el} />}
                />
            ) : (
                <NoData title='Positions' />
            )}
        </Suspense>
    );
};

export const loader = async () => {
    return defer({ positions: await getPositions() });
};

export const getPositions = async () => {
    const { token } = getDataFromStorage();
    try {
        const res = await fetch(`${ENV.adminBaseUrl}/positions`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if (!res.ok) throw res;
        const resData = await res.json();
        return transformedData(resData.data);
    } catch (error) {
        return error;
    }
};

export default memo(Postions);
