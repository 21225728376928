import { memo, useContext } from 'react';
import {
    CTable,
    CTableDataCell,
    CTableRow,
    CTableHeaderCell,
    CTableHead,
    CTableBody
} from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../store/auth';
import { CustomDate } from '@church/utilities';
import { ROLES } from '../utils/constants';

const AnnounceTable = ({ announcements }) => {
    const navigate = useNavigate();

    const { user } = useContext(AuthContext);

    const clickHandler = id => {
        if ([ROLES.admin, ROLES.super].includes(user.roles)) {
            navigate(`/dashboard/announcements/${id}`);
        }
    };

    return (
        <CTable hover striped responsive='sm'>
            <CTableHead>
                <CTableRow>
                    <CTableHeaderCell>#</CTableHeaderCell>
                    <CTableHeaderCell>Title</CTableHeaderCell>
                    <CTableHeaderCell>Description</CTableHeaderCell>
                    <CTableHeaderCell>Start Date</CTableHeaderCell>
                    <CTableHeaderCell>End Date</CTableHeaderCell>
                    <CTableHeaderCell>Status</CTableHeaderCell>
                </CTableRow>
            </CTableHead>
            <CTableBody>
                {announcements &&
                    announcements.length > 0 &&
                    announcements.map(announcement => (
                        <CTableRow
                            role='button'
                            key={announcement.id}
                            onClick={() => clickHandler(announcement.id)}
                        >
                            <CTableDataCell>
                                {announcement.count}
                            </CTableDataCell>
                            <CTableDataCell>
                                {announcement.title}
                            </CTableDataCell>
                            <CTableDataCell>
                                {`${announcement.description.substring(
                                    0,
                                    30
                                )}...`}
                            </CTableDataCell>
                            <CTableDataCell>
                                {CustomDate.formatCustomDate(
                                    announcement.startDate
                                )}
                            </CTableDataCell>
                            <CTableDataCell>
                                {CustomDate.formatCustomDate(
                                    announcement.endDate
                                )}
                            </CTableDataCell>
                            <CTableDataCell>
                                {announcement.status}
                            </CTableDataCell>
                        </CTableRow>
                    ))}
            </CTableBody>
        </CTable>
    );
};

export default memo(AnnounceTable);
