import { useState, useEffect, useCallback } from 'react';
import { ENV, STATUS, STATUS_CODE } from '../utils/constants';
import { clearSession, getDataFromStorage } from '../utils/helpers';
import { objectsAreEqual } from '@church/utilities';

export const useAuth = () => {
    const [token, setToken] = useState('');
    const [user, setUser] = useState(null);

    /**
     * Log in and set data in storage.
     * The parameters emanate from the login component.
     *
     * @param {Object} user
     * @param {String} token
     * @return void
     *
     */
    const login = useCallback((user, token) => {
        setUser(user);
        setToken(token);
        sessionStorage.setItem(
            'user',
            JSON.stringify({
                user,
                token
            })
        );
    }, []);

    /**
     * Log out and redirect to the login page.
     *
     * @return void
     *
     */
    const logout = useCallback(async () => {
        const storedData = getDataFromStorage();
        const res = await fetch(`${ENV.adminBaseUrl}/logout`, {
            headers: {
                Authorization: `Bearer ${storedData.token}`
            }
        });
        if (res.ok) {
            clearSession();
        }
        clearSession();
    }, []);

    /**
     * Evaluate context data and update page in the event of any change.
     *
     * @return void
     *
     */
    useEffect(() => {
        const storedData = getDataFromStorage();

        const fetchUser = async () => {
            try {
                const response = await fetch(
                    `${ENV.adminBaseUrl}/users/${storedData.user.id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${storedData.token}`
                        }
                    }
                );
                if (!response.ok) throw response;
                const responseData = await response.json();
                const userData = responseData.data;
                const freshUser = {
                    id: userData.user.id,
                    firstName: userData.user.firstName,
                    lastName: userData.user.lastName,
                    avatar:
                        userData.user.images && userData.user.images.length > 0
                            ? userData.user.images[0].url
                            : null,
                    roles: userData.user.roles,
                    status: userData.user.status
                };
                if (!objectsAreEqual(freshUser, storedData.user)) {
                    if (freshUser.status !== STATUS.active) {
                        clearSession();
                    }
                    login(freshUser, storedData.token);
                }
            } catch (error) {
                if (
                    error &&
                    [
                        STATUS_CODE.tooManyRequests,
                        STATUS_CODE.serviceUnavailable,
                        STATUS_CODE.unauthorized,
                        STATUS_CODE.internalServerError,
                        STATUS_CODE.methodNotAllowed
                    ].includes(error.status)
                ) {
                    clearSession();
                }
            }
        };

        if (storedData) {
            const intervalId = setInterval(fetchUser, 120000);
            return () => clearInterval(intervalId);
        }
    }, [login, logout]);

    /**
     * Evaluate the difference in time and log the user out.
     *
     * @return void
     *
     */
    useEffect(() => {
        const storedData = getDataFromStorage();
        if (storedData) {
            if (
                !!storedData.token.length &&
                storedData.user.status === STATUS.active
            ) {
                login(storedData.user, storedData.token);
            }
        }
    }, [login]);

    return {
        user,
        token,
        login,
        logout
    };
};
