import { CCol, CRow, CCard, CCardHeader, CCardBody } from '@coreui/react';
import { memo } from 'react';

const NoData = ({ title }) => {
    return (
        <CRow className='justify-content-center'>
            <CCol md={6}>
                <CCard>
                    <CCardHeader>{title}</CCardHeader>
                    <CCardBody>
                        <h1 className='text-center'>No Data</h1>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    );
};

export default memo(NoData);
