export const objectsAreEqual = (obj1, obj2, seenObjects = new Set()) => {
    // Check if the objects are of the same type
    if (typeof obj1 !== typeof obj2) {
        return false;
    }
    // Check if the objects are arrays
    if (Array.isArray(obj1) && Array.isArray(obj2)) {
        if (obj1.length !== obj2.length) {
            return false;
        }
        for (let i = 0; i < obj1.length; i++) {
            if (!objectsAreEqual(obj1[i], obj2[i], seenObjects)) {
                return false;
            }
        }
        return true;
    }
    // Check if the objects are non-null objects
    if (typeof obj1 === 'object' &&
        obj1 !== null &&
        typeof obj2 === 'object' &&
        obj2 !== null) {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
        if (keys1.length !== keys2.length) {
            return false;
        }
        if (seenObjects.has(obj1) || seenObjects.has(obj2)) {
            // Circular reference detected
            return true;
        }
        seenObjects.add(obj1);
        seenObjects.add(obj2);
        for (const key of keys1) {
            if (!keys2.includes(key) ||
                !objectsAreEqual(obj1[key], obj2[key], seenObjects)) {
                return false;
            }
        }
        return true;
    }
    // If the objects are of a primitive type, compare them directly
    return obj1 === obj2;
};
