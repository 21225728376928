import {
    CTable,
    CTableDataCell,
    CTableRow,
    CTableHeaderCell,
    CTableHead,
    CTableBody
} from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import { CustomDate } from '@church/utilities';
import { memo, useContext } from 'react';
import { ROLES } from '../utils/constants';
import { AuthContext } from '../store/auth';

const EventTable = ({ events }) => {
    const navigate = useNavigate();

    const { user } = useContext(AuthContext);

    const clickHandler = id => {
        if ([ROLES.admin, ROLES.super].includes(user.roles)) {
            navigate(`/dashboard/events/${id}`);
        }
    };

    return (
        <CTable hover striped responsive='sm'>
            <CTableHead>
                <CTableRow>
                    <CTableHeaderCell>#</CTableHeaderCell>
                    <CTableHeaderCell>Title</CTableHeaderCell>
                    <CTableHeaderCell>Organizer</CTableHeaderCell>
                    <CTableHeaderCell>Description</CTableHeaderCell>
                    <CTableHeaderCell>Status</CTableHeaderCell>
                    <CTableHeaderCell>Publish Status</CTableHeaderCell>
                    <CTableHeaderCell>Fee(€)</CTableHeaderCell>
                    <CTableHeaderCell>Start Date</CTableHeaderCell>
                    <CTableHeaderCell>End Date</CTableHeaderCell>
                </CTableRow>
            </CTableHead>
            <CTableBody>
                {events &&
                    events.length > 0 &&
                    events.map(event => (
                        <CTableRow
                            role='button'
                            key={event.id}
                            onClick={() => clickHandler(event.id)}
                        >
                            <CTableDataCell>{event.count}</CTableDataCell>
                            <CTableDataCell>{event.title}</CTableDataCell>
                            <CTableDataCell>{event.organizer}</CTableDataCell>
                            <CTableDataCell>
                                {`${event.description.substring(0, 30)}...`}
                            </CTableDataCell>
                            <CTableDataCell>{event.status}</CTableDataCell>
                            <CTableDataCell>{event.isPublished}</CTableDataCell>
                            <CTableDataCell>{event.fee}</CTableDataCell>
                            <CTableDataCell>
                                {CustomDate.formatCustomDate(event.startDate)}
                            </CTableDataCell>
                            <CTableDataCell>
                                {CustomDate.formatCustomDate(event.endDate)}
                            </CTableDataCell>
                        </CTableRow>
                    ))}
            </CTableBody>
        </CTable>
    );
};

export default memo(EventTable);
